export const HIDE = "hide";
export const SHOW = "show";

export const USERTYPE_QUERY_PARAM = "USERTYPE";
export const SKEY_QUERY_PARAM = "SKEY";
export const CLIENT_QUERY_PARAM = "CLIENT";
export const DEST_QUERY_PARAM = "DEST";
export const USERTYPE_AI2CA = "AI2CA";
export const USERTYPE_AI2GO = "AI2GO";
export const ENABLE_AEM = "aem";

export const PAGE_TITLE_INVESTOR = "Continue to Automated Investor";
export const PAGE_TITLE = "Account login";

export const DEFAULT = "default";
export const AND_CONST = "&";
export const EQUAL_CONST = "=";
export const EMPTY_STRING = "";
export const BACK_TO_STRING = "Back to";

export const IT_AK_VALUE = "i8fsGfGSDDMeaAiGGffeb43vGbIjOjZh";
export const UAT_AK_VALUE = "hptFK9zFeTTXQ0SWOmV2vN3dtO2NWlcu";
export const PROD_AK_VALUE = "i456u8uri0I7uts8w8jFzlmTX8tuABWb";
export const IT_ENV = "it";
export const LOCALHOST_ENV = "localhost";
export const UAT_ENV = "uat";
export const EMP_ENV = "emp";
export const PROD_ENV = "prod";
export const PRODUCTION = "production";
export const ALPHA = "alpha";
export const UAT = "uat";
export const PERF_ENV = "perf1";
export const PENTEST_ENV ="pentest1";
export const CHANNEL_PERF="performance";
export const CHANNEL_WEB="web";
export const UAT_AAE_ENV = "aae";

//SITECAT CONSTANTS
export const APP_NAME_FOR_SITE_CAT_DESKTOP = 'OLB';
export const UX_NAME_FOR_SITE_CAT_DESKTOP = 'desktop';

export const APP_NAME_FOR_SITE_CAT_MOBILE = 'MBL';
export const UX_NAME_FOR_SITE_CAT_MOBILE = 'touch';

export const CLIENT_NAME_FOR_SITE_CAT = 'olb_standalone';

export const CURRENT_SESSION = 'currentSession';

export const DIY_CUSTOMER_DASHBOARD_URL = "/digital/servicing/customer-dashboard";
export const SIGNON_WITH_TRANSMIT_URL = "/Auth/Signon/SignonWithTransmit";
export const TUX_REDIRECT_URL = "/TUX/public/index.html?redirectfromDIY=true";

export const SYNC_DEV_UTAG_URL = "//tags.tiqcdn.com/utag/usbank/olblogin/dev/utag.sync.js";
export const SYNC_PROD_UTAG_URL = "//tags.tiqcdn.com/utag/usbank/olblogin/prod/utag.sync.js";
export const SYNC_QA_UTAG_URL = "//tags.tiqcdn.com/utag/usbank/olblogin/qa/utag.sync.js";

export const DEV_UTAG_URL = "//tags.tiqcdn.com/utag/usbank/olblogin/dev/utag.js";
export const PROD_UTAG_URL = "//tags.tiqcdn.com/utag/usbank/olblogin/prod/utag.js";
export const QA_UTAG_URL = "//tags.tiqcdn.com/utag/usbank/olblogin/qa/utag.js";

export const CALL_SIGN_URL = "/Proxy/CS/in/web-sdk/v1/static/web-sdk.js";

export const TEXT_JAVASCRIPT = "text/javascript";
export const SCRIPT = "script";

export const UXREFRESH = "UXREFRESH=TRUE";
export const ISPILOT = "ISPILOT=TRUE";
export const ISWIDGET = "ISWIDGET=TRUE";
export const SLASH = "/";
export const USERTYPE_HAR = "HAR";

export const LEGACY_LAS = '/OLS/LoginAssistant/#/GenericLanding';
export const REACT_LAS = '/digital/loginhelp/';

export const LEGACY_RESET_PASSWORD = '/OLS/LoginAssistant/ResetPassword';

export const LEGACY_FORGOT_SHIELD_ANSWER = '/OLS/LoginAssistant/ResetAnswers';
export const REACT_FORGOT_SHIELD_ANSWER = '/digital/loginhelp/IDshield/';

export const APPD_KEY_NONPROD = 'AD-AAB-AAX-FJG';
export const APPD_KEY_PROD = 'AD-AAB-AAX-FJH';

//logger
export const LOGGER_BUSINESS_CAPABILITY  ="businessCapability";
export const LOGGER_BUSINESS_FUNCTIONALITY = "businessFunctionality";
export const LOGGER_STEPNAME  = "stepName";
export const LOGGER_EVENT_STATUS = "eventStatus";
export const LOGGER_ACTION = "action";
export const LOGGER_COMP_LOGIN = "Login";
export const LOGGER_EVENT_SIGNONWITHTRANSMIT_REQ = "signOnWithTransmitAPICallInitiate";
export const LOGGER_EVENT_SIGNONWITHTRANSMIT_SUCCESS = "signOnWithTransmitAPICallSuccess";
export const LOGGER_EVENT_SIGNONWITHTRANSMIT_FAILURE = "signOnWithTransmitAPICallFailure";
export const LOGGER_EVENT_DIY_REDIRECT = "redirectToDIYDashboard";
export const LOGGER_EVENT_SSO_REDIRECT = "redirectToNewSSO";

export const BRAND_NAMES_TO_REDIRECT = ['AEROMEX', 'ACE', 'CAC', 'GYM', 'LIFEMIL', 'LAN', 'X', 'D', 'U', 'V', 'W'];

export const PROD_ROUTING_KEY = "PROD";

export const PROD_ORG_ID = 'org-chna18fb-w021qwxv-3y4vxmqga-gyg6gwzq';
export const LOCAL_ORG_ID = 'org-362ce9yq-qrb0c4qh-wb9qe426j-g87j2c0p';

// call sign sanf constant

export const INIT_SIGNON_TRANSMIT_API = '{"#loginPageForm":"signOnWithTransmit"}';

